import {
  CButton,
  CCloseButton,
  CCol,
  CCollapse,
  CFormCheck,
  CFormInput,
  CModal,
  CModalBody,
  COffcanvas,
  COffcanvasBody,
  COffcanvasHeader,
  COffcanvasTitle,
  CPopover,
  CRow,
  CSpinner,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow
} from "@coreui/react";
import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import { useToast } from "../../../hooks/useToast";
import { useMutation, useQueryClient } from "react-query";
import { useCookie } from "../../../hooks/useCookie";
import apiService from "../../../service/apiService";
import { DELETE_ACTIONS } from "../../../config/constants";
import { general } from "../../../locales/general";
import { colors } from "../../../config/theme";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";
import DeleteIcon from "../../../components/icons/DeleteIcon";
import EditIcon from "../../../components/icons/EditIcon";
import PromptModal from "../../../components/modals/PromptModal";
import { useAuth } from "../../../hooks/useAuth";
import VisibleSvg from "../../../components/icons/VisibleIcon";
import { formatDateString } from "../../../helpers/general";
import { format } from "date-fns";

interface BodyRowProp {
  Review: Review;
  deletedList: React.Dispatch<BulkDeleteActions>;
  onView: () => void;
}

function TableBodyRowContent(props: BodyRowProp) {
  const { Review, deletedList, onView } = props;

  const [isChecked, setIsChecked] = useState(false);
  const { toastSuccess, toastError } = useToast();
  const { token } = useCookie("vToken");
  const queryClient = useQueryClient();
  const [showModal, setShowModal] = useState(false);
  const { isAdmin } = useAuth();

  const deleteReview = useMutation((id: string) => {
    return apiService.MakeDeleteRequest(`reviews/${id}`, token);
  });

  useEffect(() => {
    if (isChecked) {
      deletedList({ type: DELETE_ACTIONS.ADD, id: Review.id });
    } else {
      deletedList({ type: DELETE_ACTIONS.REMOVE, id: Review.id });
    }
  }, [deletedList, isChecked, Review.id]);

  const removeReview = () => {
    deleteReview.mutate(Review.id, {
      onError(error) {
        console.log(error);
        toastError(general.fr.message.operationFailed);
      },
      onSuccess() {
        toastSuccess(general.fr.message.reviewDeleted);
        queryClient.invalidateQueries("getPlaceReviews");
      }
    });
  };

  return (
    <CTableRow
      style={{
        borderLeft: isChecked ? `3px solid ${colors.primary}` : "",
        width: "100%",
        position: "relative"
      }}
    >
      <CTableDataCell className="p-3 ">
        {isAdmin === true ? (
          <CFormCheck
            id="flexCheckDefault"
            label=""
            defaultChecked={isChecked}
            onChange={e => setIsChecked(e.target.checked)}
            color={colors.yellow}
          />
        ) : null}
        <span className="ms-2 text-sm">
          {new Date(Review?.createdAt).toLocaleDateString("fr")}
        </span>
      </CTableDataCell>

      <CTableDataCell className="p-3 text-capitalize text-sm">
        {Review?.user?.fullName}
      </CTableDataCell>

      <CTableDataCell className="p-3 text-capitalize text-sm">
        {Review?.user?.email}
      </CTableDataCell>
      <CTableDataCell className="p-3 text-capitalize text-sm">
        <CPopover
          content={Review?.comment}
          placement="bottom"
          trigger="click"
          title="Review"
        >
          <span className="pointer">
            {" "}
            {Review?.comment.substring(0, 8)} ...
          </span>
        </CPopover>
      </CTableDataCell>
      <CTableDataCell className="p-3 text-capitalize text-sm">
        {Review?.rating}/5
      </CTableDataCell>

      <CTableDataCell className="p-3">
        <button onClick={onView} className="outline-none border-0 btn btn-sm">
          <VisibleSvg className="fill-primary" />
        </button>
        {isAdmin === true ? (
          <button
            className="outline-none border-0 bg-transparent"
            onClick={() => setShowModal(true)}
          >
            {deleteReview.isLoading ? (
              <CSpinner size="sm" />
            ) : (
              <DeleteIcon
                width="20"
                height="20"
                fill={colors.primary}
                className="ms-2"
              />
            )}
          </button>
        ) : null}
      </CTableDataCell>
      <PromptModal
        open={showModal}
        onAccept={() => removeReview()}
        onClose={() => setShowModal(false)}
      />
    </CTableRow>
  );
}

interface TableProps {
  Reviews: Review[];
  ReviewToBeDeleted: React.Dispatch<BulkDeleteActions>;
}

export default function PlaceReviewsTable({
  Reviews,
  ReviewToBeDeleted
}: TableProps) {
  const [selectedReview, setSelectedReview] = useState<Review | null>(null);

  return (
    <div className="mt-3">
      <CTable>
        <CTableHead>
          <CTableRow>
            <CTableHeaderCell scope="col">Date</CTableHeaderCell>
            <CTableHeaderCell scope="col">Nom complet</CTableHeaderCell>

            <CTableHeaderCell scope="col">Email</CTableHeaderCell>
            <CTableHeaderCell scope="col">Avis</CTableHeaderCell>
            <CTableHeaderCell scope="col">
              Note d&apos;évaluation
            </CTableHeaderCell>

            <CTableHeaderCell scope="col"></CTableHeaderCell>
          </CTableRow>
        </CTableHead>
        <CTableBody>
          {Reviews?.map(Review => (
            <TableBodyRowContent
              Review={Review}
              key={Review?.id}
              deletedList={ReviewToBeDeleted}
              onView={() => setSelectedReview(Review)}
            />
          ))}{" "}
        </CTableBody>
      </CTable>
      {selectedReview ? (
        <ReviewDetails
          review={selectedReview}
          onClose={() => setSelectedReview(null)}
        />
      ) : null}
    </div>
  );
}

interface ReviewDetailsProps {
  onClose: () => void;
  review: Review;
}
function ReviewDetails(props: ReviewDetailsProps) {
  const { onClose, review } = props;
  return (
    <COffcanvas backdrop={true} placement="start" visible onHide={onClose}>
      <COffcanvasHeader className="mb-0 border border-bottom">
        <COffcanvasTitle>{`Évalué par ${review?.user?.fullName}`}</COffcanvasTitle>
        <CCloseButton className="text-reset" onClick={onClose} />
      </COffcanvasHeader>

      <COffcanvasBody>
        <div className="p-1 mt-2">
          <p className="mb-0">
            Commenté le : {formatDateString(review.createdAt)} à{" "}
            {format(new Date(review?.createdAt), "HH:mm")}
          </p>
        </div>

        <div className="border-bottom p-1">
          <p className="mb-0">
            Date de réservation : {formatDateString(review?.booking?.date)} à{" "}
            {review?.booking?.hour}
          </p>
        </div>

        <div className="text-gray p-2 mt-2">
          <h5 className="fs-6">Avis</h5>
          {review?.comment}
        </div>
      </COffcanvasBody>
    </COffcanvas>
  );
}
